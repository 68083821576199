<template>
    <div>
        <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
            {{ successMessage }}
        </div>
        <b-card>
            <b-row>
                <div class="col-12 mt-16">
                    <b-form @submit.prevent="addUser" v-if="show">
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                Order Information
                            </h4>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Product Name:" label-for="product_name">
                                    <b-form-input id="product_name" placeholder="Enter product name" autocomplete="off"
                                        v-model="product_name" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                            <b-form-group id="input-group-1" label="User Name:" label-for="user_name">
                                <b-form-input id="user_name" placeholder="Enter user name" autocomplete="off"
                                    v-model="user_name" disabled>
                                </b-form-input>
                            </b-form-group>
                        </div>

    
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Address:" label-for="address">
                                    <b-form-input id="address" type="text" placeholder="Enter street address"
                                        v-model="address" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Quantity:" label-for="quantity">
                                    <b-form-input id="quantity" type="quantity" placeholder="Enter quantity"
                                        v-model="quantity" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Qunatity Amount:" label-for="amount">
                                    <b-form-input id="amount" placeholder="Enter quantity amount "
                                        v-model="amount" disabled></b-form-input>
                                </b-form-group>
                            </div>
                           
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Status:" label-for="status">
                                    <b-form-input id="status" placeholder="Enter status" v-model="status" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                    </b-form>
                </div>
                <div v-if="codeActive" class="col-12 mt-24 hljs-container" :class="{ active: codeActiveClass }">
                    <pre v-highlightjs>
              <code class="hljs html">
                {{ codeText }}
              </code>
            </pre>
                </div>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BSpinner,
} from "bootstrap-vue";
import axios from "../../../axios";
import { BToast } from "bootstrap-vue";
import code from "@/view/components/data-entry/form/code";

export default {
    data() {
        return {
            show: true,
            codeText: code.introduction,
            codeActive: false,
            codeActiveClass: false,
            product_name: "",
            user_name: "",
            address: "",
            quantity: "",
            amount: "",
            status: '',
            successMessage: "",
        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BToast,
        BSpinner,
    },

    created() {
        // Load the order data when the component is created
        const userId = this.$route.params.id;
        axios
            .get(`OrderShow/${userId}`)
            .then((response) => {
                const data = response.data.data;
                this.product_name = data.product.name; 
                this.user_name = data.user.first_name; 
                this.address = data.address;
                this.status = data.status;
                this.quantity = data.quantity;
                this.amount = data.amount;
            })
            .catch((error) => {
                console.error("Error fetching order data:", error);
            });
    },
    methods: {
        codeClick() {
            this.codeActive = !this.codeActive;
        },
        onProfilePictureChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.image = file;
            }
        },
    },
};
</script>

